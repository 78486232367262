import React from "react"
import SignInLayout from "../components/signin-layout"

import SEO from "../components/seo"
import SignUpComponent from "../domain/sign-up"

const SignInPage = ({ location }) => {
  console.log(location)
  // console.log(process.env.GATSBY_MEDUSA_BACKEND_URL)
  // console.log(process.env.GATSBY_NEXT_PUBLIC_URL_SIGN_UP_ADMIN)
  return (
    <SignInLayout>
      <SEO title="Login" />
      <SignUpComponent location={location} />
    </SignInLayout>
  )
}

export default SignInPage
