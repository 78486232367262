// eslint-disable-next-line react/display-name
import { Space, Select, Form, Button, Input } from "antd"
import React, { useContext, useRef, useState } from "react"
import { AccountContext } from "../../../context/account"
import axios from "axios"
import { apiAddress, onKeyPressNumber } from "../../../customUtil/global"
import textAndRules from "../../../utils/textAndRules"

const { Option } = Select
const FormItemAddress = ({ form }) => {
  const [loadingGetAddress, setLoadingGetAddress] = useState(false)
  const [errorZipCode, setErrorZipCode] = useState(false)
  const { regions } = useContext(AccountContext)
  const number2AddressRef = useRef()
  const buildingNameAddressRef = useRef()

  const handleGetRegion = () => {
    setLoadingGetAddress(true)
    const info = form.getFieldsValue()
    const api = info?.number1 + info?.number2
    axios
      .get(apiAddress(api))
      .then((response) => {
        setLoadingGetAddress(false)

        setErrorZipCode(false)
        form.setFieldsValue({
          prefecture: response.data.results[0].address1,
          city: response.data.results[0].address2,
          town: response.data.results[0].address3,
        })
        buildingNameAddressRef.current.focus()
      })
      .catch((err) => {
        setErrorZipCode(true)
        setLoadingGetAddress(false)
      })
  }

  return (
    <Form.Item
      label={
        <Space size={14}>
          {
            <div className="bg-[#FFF1F0] text-[#E75B53] text-[11px] rounded-[5px] p-[3px]">
              {"必須"}{" "}
            </div>
          }
          <div className="font-bold text-[14px]">{"住所"}</div>
        </Space>
      }
      style={{ flex: 1 }}
    >
      <div className={"fix_select_address"}>
        <Space align="baseline">
          <div>〒</div>
          <Form.Item
            name="number1"
            rules={[
              {
                required: true,
                message: textAndRules.pleaseInput,
              },

              {
                whitespace: true,
                message: `空白を入力できません。`,
              },
            ]}
          >
            <Input
              placeholder="000"
              style={{ width: "5rem" }}
              maxLength={3}
              onKeyPress={onKeyPressNumber}
              onChange={(e) => {
                if (e.target.value.length > 2) {
                  number2AddressRef.current.focus()
                }
              }}
              // onFocus={(e) => {
              //   if (e.target.value.length === 3) {
              //     numberAddressRef.current.focus();
              //   }
              // }}
            />
          </Form.Item>
          <div>－</div>
          <Form.Item
            name="number2"
            rules={[
              {
                required: true,
                message: textAndRules.pleaseInput,
              },

              {
                whitespace: true,
                message: `空白を入力できません。`,
              },
            ]}
          >
            <Input
              maxLength={4}
              placeholder="0001"
              onKeyPress={onKeyPressNumber}
              style={{ width: "6rem" }}
              ref={number2AddressRef}
            />
          </Form.Item>
          <Button
            style={{ color: "#ecb427", border: "1px solid #ecb427" }}
            loading={loadingGetAddress}
            onClick={handleGetRegion}
          >
            住所表示
          </Button>
        </Space>
        {errorZipCode && (
          <div className="error">郵便番号から住所が見つかりませんでした。</div>
        )}
        <Space
          style={{
            display: "flex",
            flexDirection: "row",
            marginTop: 12,
          }}
          align="baseline"
        >
          <Form.Item
            name="prefecture"
            rules={[
              {
                required: true,
                message: `都道府県は必須項目です。`,
              },
              {
                whitespace: true,
                message: `空白を入力できません。`,
              },
            ]}
          >
            <Select
              placeholder="都道府県"
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) => option.children.includes(input)}
              style={{ width: "100%" }}
              filterSort={(optionA, optionB) =>
                optionA.children
                  .toLowerCase()
                  .localeCompare(optionB.children.toLowerCase())
              }
              notFoundContent={"データなし。"}
            >
              {regions?.map((el) => {
                return (
                  <Option key={el.name} value={el.name}>
                    {el.name}
                  </Option>
                )
              })}
            </Select>
          </Form.Item>
          <Form.Item
            name="city"
            rules={[
              {
                required: true,
                message: `市区町村は必須項目です。`,
              },
              {
                whitespace: true,
                message: `空白を入力できません。`,
              },
            ]}
          >
            <Input style={{ width: "100%" }} placeholder="市区町村" />
          </Form.Item>
        </Space>
        <div style={{ marginTop: 12 }}>
          <Form.Item
            name="town"
            rules={[
              {
                required: true,
                message: `町域・番地は必須項目です。`,
              },
              {
                whitespace: true,
                message: `空白を入力できません。`,
              },
            ]}
          >
            <Input style={{ width: "93%" }} placeholder="町域・番地" />
          </Form.Item>
        </div>
        <div style={{ marginTop: 12 }}>
          <Form.Item
            name="building_name"
            rules={[
              {
                required: false,
                message: textAndRules.pleaseInput,
              },

              {
                whitespace: true,
                message: `空白を入力できません。`,
              },
            ]}
          >
            <Input
              style={{ width: "93%" }}
              ref={buildingNameAddressRef}
              placeholder="建物名"
            />
          </Form.Item>
        </div>
      </div>
    </Form.Item>
  )
}
export default FormItemAddress
