import React from "react"
import { Form, Select } from "antd"
import textAndRules from "../../utils/textAndRules"

const FormSelect = ({
  name,
  required,
  labelName,
  option,
  placeholder,
  handleChange,
}) => {
  if (!handleChange) {
    handleChange = () => {}
  }
  return (
    <Form.Item
      label={
        <div className="flex gap-[14px]">
          {required && (
            <div className="bg-[#FFF1F0] text-[#E75B53] text-[11px] rounded-[5px] p-[3px]">
              {"必須"}{" "}
            </div>
          )}
          <div className="font-bold text-[14px]">{labelName}</div>
        </div>
      }
    >
      <Form.Item
        name={name}
        rules={[
          {
            required: required,
            message: textAndRules.pleaseSelect,
          },
        ]}
      >
        <Select
          allowClear
          placeholder={placeholder}
          onChange={(e) => {
            handleChange(e)
          }}
        >
          {option &&
            option.map((e) => {
              return (
                <Select.Option key={e.name} value={e.id}>
                  {e.name}
                </Select.Option>
              )
            })}
        </Select>
      </Form.Item>
    </Form.Item>
  )
}
export default FormSelect
