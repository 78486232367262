import React, { useState } from "react"
import { Input } from "antd"

const InputSurnameAndName = ({ value = {}, onChange }) => {
  const [surname, setSurname] = useState(null)
  const [name, setName] = useState(null)

  const triggerChange = (changedValue) => {
    onChange?.({
      surname,
      name,

      ...value,
      ...changedValue,
    })
  }

  const onSurnameChange = (e) => {
    const newValue = e.target.value

    if (!("number" in value)) {
      setSurname(newValue)
    }

    triggerChange({
      surname: newValue,
    })
  }
  const onNameChange = (e) => {
    const newValue = e.target.value

    if (!("number" in value)) {
      setName(newValue)
    }

    triggerChange({
      name: newValue,
    })
  }

  return (
    <div className="flex flex-row gap-[8px]" style={{ padding: "0 0 16px 0" }}>
      <div style={{ width: "50%" }}>
        <Input
          value={value?.surname || surname}
          onChange={onSurnameChange}
          style={{ width: "100%" }}
          placeholder="姓"
        />
      </div>
      <div style={{ width: "50%" }}>
        <Input
          value={value?.name || name}
          onChange={onNameChange}
          style={{ width: "100%" }}
          placeholder="名"
        />
      </div>
    </div>
  )
}

export default InputSurnameAndName
