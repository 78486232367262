// import { navigate } from "gatsby"

import React from "react"
import { useWindowDimensions } from "../../../hooks/use-window-dimensions"
import "../style.scss"

type Props = {
  label: string
  value: string
}

const LabelAndValueInputed = ({ label, value }: Props) => {
  const { width, height } = useWindowDimensions()
  return (
    <div>
      <div
        className={`flex gap-[14px]  ${width >= 768 ? "flex-row" : "flex-col"}`}
      >
        <div className="flex gap-[14px] flex-row" style={{ width: 170 }}>
          <div className="bg-[#FFF1F0] text-[#E75B53] text-[11px] rounded-[5px] p-[3px]">
            {"必須"}
          </div>
          <div className="font-bold text-[14px]">{label}</div>
        </div>
        <div> {value}</div>
      </div>

      <div className="line_full_width mt-8"></div>
    </div>
  )
}

export default LabelAndValueInputed
