// import { navigate } from "gatsby"

import React, { useState } from "react"
import "./style.scss"

import BreadcrumbSignIn from "./breadcrumb"
import DisplaySignUp from "./displaySignUp"
import DisplayConfirmSignUp from "./displayConfirmSignUp"

const SignUpComponent = ({ location }) => {
  const [confirmSignIn, setConfirmSignUp] = useState(false)
  const [dataInput, setDataInput] = useState(null)
  console.log("dataInput", dataInput)

  return (
    <div>
      <div className="pt-3 pl-8 fix_ant_item">
        <BreadcrumbSignIn confirmSignIn={confirmSignIn} />
      </div>
      {!confirmSignIn && (
        <DisplaySignUp
          dataInput={dataInput}
          setConfirmSignUp={setConfirmSignUp}
          setDataInput={setDataInput}
          location={location}
        />
      )}
      {confirmSignIn && (
        <DisplayConfirmSignUp
          dataInput={dataInput}
          setConfirmSignUp={setConfirmSignUp}
        />
      )}
    </div>
  )
}

export default SignUpComponent
