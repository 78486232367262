import React, { useRef } from "react"

import { Form, Input } from "antd"
import { checkEmail } from "../../services/user"
import textAndRules from "../../utils/textAndRules"
export function isEmail(emailAddress: any) {
  const regexEmail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
  if (emailAddress?.match(regexEmail)) {
    return true
  } else {
    return false
  }
}
const FormTextArea = ({
  name,
  required,
  placeholder,
  labelName,
  placeEmail,
}) => {
  const typingTimeoutRef = useRef<any>(null)

  const checkNameAndSurname = (_: any, value: any) => {
    if (value) {
      if (isEmail(value)) {
        // console.log("hihi");
        if (typingTimeoutRef.current) {
          clearTimeout(typingTimeoutRef.current)
        }
        return new Promise<void>((resolve, reject) => {
          typingTimeoutRef.current = setTimeout(async () => {
            const { response }: any = await checkEmail(value)
            if (response.data.exists) {
              reject(new Error("既にメールアドレスが存在します。"))
            }
            resolve()
          }, 500)
        })
      }
      return Promise.resolve()
    }
    // eslint-disable-next-line prefer-promise-reject-errors
    return Promise.reject()
  }

  return (
    <Form.Item
      label={
        <div className="flex gap-[14px]">
          {required && (
            <div className="bg-[#FFF1F0] text-[#E75B53] text-[11px] rounded-[5px] p-[3px]">
              {"必須"}{" "}
            </div>
          )}
          <div className="font-bold text-[14px]">{labelName}</div>
        </div>
      }
    >
      <Form.Item
        name={name}
        rules={[
          {
            required: required,
            message: textAndRules.pleaseInput,
          },
          {
            type: placeEmail && "email",
            message: `${labelName}は有効なメールではありません。`,
          },
          {
            whitespace: true,
            message: placeEmail ? "" : `空白を入力できません。`,
          },
          {
            validator: placeEmail ? checkNameAndSurname : null,
          },
        ]}
      >
        <Input placeholder={placeholder} />
      </Form.Item>
      {placeEmail && (
        <div className="font-medium">
          <div> PCメールが受信できるアドレスを入力ください。</div>
          <div>
            携帯電話会社のメールアドレス（@docomo.ne.jp、@softbank.ne.jp、
          </div>
          <div> @ezweb.ne.jpなど）は可能なかぎり避けてください。</div>
          <div>または迷惑メール除外設定してください。</div>
        </div>
      )}
    </Form.Item>
  )
}
export default FormTextArea
